import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterUAEC4IR(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - UAE Centre for the 4th Industrial Revolution' />
      <RegistrationFrom source='UAE C4IR' page='uae-c4ir' imageName='c4ir_new' imageAlt='c4ir-expo' />
    </Layout>
  );
}
